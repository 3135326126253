import poolsConfig from 'config/constants/pools'
import masterChefABI from 'config/abi/masterchef.json'
import sousChefABI from 'config/abi/sousChef.json'
import wingsABI from 'config/abi/wings.json'
import wbnbABI from 'config/abi/weth.json'
import multicall from 'utils/multicall'
import { getAddress, getMasterChefAddress, getWftmAddress } from 'utils/addressHelpers'
import BigNumber from 'bignumber.js'
import { FWINGS_PER_BLOCK } from 'config'

export const fetchPoolsBlockLimits = async () => {
  const poolsWithEnd = poolsConfig.filter((p) => ![0 /* 19 , 1, 2, 15 */].includes(p.sousId))
  const callsStartTime = poolsWithEnd.map((poolConfig) => {
    return {
      address: getAddress(poolConfig.contractAddress),
      name: 'startTime',
    }
  })
  const callsEndBlock = poolsWithEnd.map((poolConfig) => {
    return {
      address: getAddress(poolConfig.contractAddress),
      name: 'bonusEndTime',
    }
  })

  const starts = await multicall(sousChefABI, callsStartTime)
  const ends = await multicall(sousChefABI, callsEndBlock)
  return poolsWithEnd.map((wingsPoolConfig, index) => {
    const startTime = starts[index]
    const endTime = ends[index]
    return {
      sousId: wingsPoolConfig.sousId,
      startTime: new BigNumber(startTime).toJSON(),
      endTime: new BigNumber(endTime).toJSON(),
    }
  })
}

export const fetchPoolsTotalStaking = async () => {
  console.log('started fetchPoolsTotalStaking')
  const nonFtmPools = poolsConfig.filter((p) => p.stakingToken.symbol !== 'FTM')
  const ftmPool = poolsConfig.filter((p) => p.stakingToken.symbol === 'FTM')

  const callsNonFtmPools = nonFtmPools.map((poolConfig) => {
    return {
      address: getAddress(poolConfig.stakingToken.address),
      name: 'balanceOf',
      params: [getAddress(poolConfig.contractAddress)],
    }
  })

  const callsFtmPools = ftmPool.map((poolConfig) => {
    return {
      address: getWftmAddress(),
      name: 'balanceOf',
      params: [getAddress(poolConfig.contractAddress)],
    }
  })

  const nonBnbPoolsTotalStaked = await multicall(wingsABI, callsNonFtmPools)
  const bnbPoolsTotalStaked = await multicall(wbnbABI, callsFtmPools)
  const poolsWithAllocPoint = await Promise.all(
    nonFtmPools.map(async (pool) => {
      if (![0 /* 19 , 1, 2, 15 */].includes(pool.sousId)) {
        return pool
      }
      const [info, totalAllocPoint] = await multicall(masterChefABI, [
        {
          address: getMasterChefAddress(),
          name: 'poolInfo',
          params: [pool.sousId],
        },
        {
          address: getMasterChefAddress(),
          name: 'totalAllocPoint',
        },
      ])

      const allocPoint = new BigNumber(info.allocPoint._hex)
      const poolWeight = allocPoint.div(new BigNumber(totalAllocPoint))

      return {
        ...pool,
        tokenPerBlock: FWINGS_PER_BLOCK.times(poolWeight).toJSON(),
      }
    }),
  )

  return [
    ...poolsWithAllocPoint.map((p, index) => ({
      sousId: p.sousId,
      totalStaked: new BigNumber(nonBnbPoolsTotalStaked[index]).toJSON(),
      tokenPerBlock: p.tokenPerBlock,
    })),
    ...ftmPool.map((p, index) => ({
      sousId: p.sousId,
      totalStaked: new BigNumber(bnbPoolsTotalStaked[index]).toJSON(),
    })),
  ]
}
