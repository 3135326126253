const tokens = {
  ftm: {
    symbol: 'FTM',
    projectLink: 'https://fantom.foundation/',
  },
  fwings: {
    symbol: 'fWINGS',
    address: {
      250: '0x3D8f1ACCEe8e263F837138829B6C4517473d0688',
      4002: '',
    },
    decimals: 18,
    projectLink: 'https://fantom.jetswap.finance/',
  },
  wftm: {
    symbol: 'WFTM',
    address: {
      250: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
      4002: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
    },
    decimals: 18,
    projectLink: 'https://fantom.foundation/',
  },
  fusdt: {
    symbol: 'fUSDT',
    address: {
      250: '0x049d68029688eabf473097a2fc38ef61633a3c7a',
      4002: '',
    },
    decimals: 6,
    projectLink: 'https://tether.to/',
  },
  usdc: {
    symbol: 'USDC',
    address: {
      250: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
      4002: '',
    },
    decimals: 6,
    projectLink: 'https://www.centre.io/',
  },
  eth: {
    symbol: 'ETH',
    address: {
      250: '0x74b23882a30290451A17c44f4F05243b6b58C76d',
      4002: '',
    },
    decimals: 18,
    projectLink: 'https://www.centre.io/',
  },
  btc: {
    symbol: 'BTC',
    address: {
      250: '0x321162Cd933E2Be498Cd2267a90534A804051b11',
      4002: '',
    },
    decimals: 18,
    projectLink: 'https://www.wbtc.network/',
  },
  dai: {
    symbol: 'DAI',
    address: {
      250: '0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E',
      4002: '',
    },
    decimals: 18,
    projectLink: 'https://makerdao.com/',
  },
  link: {
    symbol: 'LINK',
    address: {
      250: '0xb3654dc3D10Ea7645f8319668E8F54d2574FBdC8',
      4002: '',
    },
    decimals: 18,
    projectLink: 'https://chain.link/',
  },
  boo: {
    symbol: 'BOO',
    address: {
      250: '0x841FAD6EAe12c286d1Fd18d1d525DFfA75C7EFFE',
      4002: '',
    },
    decimals: 18,
    projectLink: 'https://spookyswap.finance/',
  },
  bhc: {
    symbol: 'BHC',
    address: {
      250: '0x7BEB05cf5681f402E762F8569c2Fc138a2172978',
      4002: '',
    },
    decimals: 18,
    projectLink: 'https://billionhappiness.finance/',
  },
  spirit: {
    symbol: 'SPIRIT',
    address: {
      250: '0x5Cc61A78F164885776AA610fb0FE1257df78E59B',
      4002: '',
    },
    decimals: 18,
    projectLink: 'https://www.spiritswap.finance/',
  },
  sing: {
    symbol: 'SING',
    address: {
      250: '0x53D831e1db0947c74e8a52618f662209ec5dE0cE',
      4002: '',
    },
    decimals: 18,
    projectLink: 'https://singular.farm/',
  },
  undead: {
    symbol: 'UNDEAD',
    address: {
      250: '0x551C61DB482289994e7d426Fc4DB6493918bB81D',
      4002: '',
    },
    decimals: 18,
    projectLink: 'https://undead.to/',
  },
  fpup: {
    symbol: 'fPUP',
    address: {
      250: '0x900825EA297c7c5D1F6fA54146849BC41EdDAf29',
      4002: '',
    },
    decimals: 18,
    projectLink: 'https://fantompup.finance/',
  },
  hps: {
    symbol: 'HPS',
    address: {
      250: '0xAC1F25AEE575D35C668B0a4D336f20E3e92adCd2',
      4002: '',
    },
    decimals: 18,
    projectLink: 'https://billionhappiness.finance/',
  },
  fast: {
    symbol: 'FAST',
    address: {
      250: '0x0299461eE055bbb6dE11fAfE5a0636A0C3Bd5E8d',
      4002: '',
    },
    decimals: 18,
    projectLink: 'https://fastyield.app/',
  },
}

export default tokens
