import tokens from './tokens'
import { PoolConfig, PoolCategory } from './types'

const pools: PoolConfig[] = [
  {
    sousId: 0,
    stakingToken: tokens.fwings,
    earningToken: tokens.fwings,
    contractAddress: {
      4002: '',
      250: '0x9180583C1ab03587b545629dd60D2be0bf1DF4f2',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0',
    sortOrder: 1,
    isFinished: false,
    depositFee: 0,
  },
  {
    sousId: 1,
    stakingToken: tokens.fwings,
    earningToken: tokens.bhc,
    contractAddress: {
      4002: '',
      250: '0x1E3c2717cdCE6489b6C96b64DE25aFf972D3F72c',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.000123',
    sortOrder: 1,
    isFinished: false,
    depositFee: 4,
  },
  {
    sousId: 2,
    stakingToken: tokens.fwings,
    earningToken: tokens.spirit,
    contractAddress: {
      4002: '',
      250: '0x0927340A0EcEB2410526Ba88aF2e295BEA412AF4',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.00893',
    sortOrder: 1,
    isFinished: false,
    depositFee: 4,
  },
  {
    sousId: 3,
    stakingToken: tokens.fwings,
    earningToken: tokens.undead,
    contractAddress: {
      4002: '',
      250: '0x33c5FbE4772aBA8235f62010c72857039dB0EF18',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.077',
    sortOrder: 1,
    isFinished: false,
    depositFee: 4,
  },
  {
    sousId: 4,
    stakingToken: tokens.fwings,
    earningToken: tokens.fpup,
    contractAddress: {
      4002: '',
      250: '0x3De32Fe1Dc3FDa78A9bcc14fe10f3924b6891122',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.000021',
    sortOrder: 1,
    isFinished: false,
    depositFee: 4,
  },
  {
    sousId: 5,
    stakingToken: tokens.fwings,
    earningToken: tokens.hps,
    contractAddress: {
      4002: '',
      250: '0xaa621BEC9bB1280675FFa290deAcb610230a7916',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.001',
    sortOrder: 1,
    isFinished: false,
    depositFee: 4,
  },
]

export default pools
