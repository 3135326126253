import { Ifo } from './types'

const ifos: Ifo[] = [
  {
    id: 'flory',
    address: '0xB5824383FBd206aA6557A1BcEEBd6a7ac0B41bF6',
    isActive: true,
    name: 'Yield Parrot',
    subTitle: ' Yield Parrot is a state-of-the-art yield aggregator & optimizer',
    description:
      'Yield Parrot is a state-of-the-art yield aggregator & optimizer. At yieldparrot.finance users can find the best opportunities within the DeFi ecosystem, with a world-class vault offering, fully optimized smart contracts, and a renowned partnership plan',
    launchDate: 'Dec. 1',
    launchTime: '5 PM GMT',
    saleAmount: '250,000 fLORY',
    raiseAmount: '$500,000',
    cakeToBurn: '$1,500,000',
    projectSiteUrl: 'https://yieldparrot.finance',
    currency: 'USDC',
    currencyAddress: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    currencyDecimals: 6,
    tokenDecimals: 18,
    tokenSymbol: 'fLORY',
    releaseTime: 1637748343,
    campaignId: '511080000',
    maxDepositAmount: 3000,
  },
  {
    id: 'fast',
    address: '0x42EA8c96bE5532759f24bF35296B14A4610Dc25F',
    isActive: false,
    name: 'Fastyield',
    subTitle: 'FAST is a governance token native to the Fastyield platform',
    description:
      'Fastyield is a yield optimizer, which enables yield aggregation for holders and users of FAST token.',
    launchDate: 'Nov. 16',
    launchTime: '5 PM GMT',
    saleAmount: '60,000 FAST',
    raiseAmount: '$500,000',
    cakeToBurn: '$1,500,000',
    projectSiteUrl: 'https://fastyield.app',
    currency: 'USDC',
    currencyAddress: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    currencyDecimals: 6,
    tokenDecimals: 18,
    tokenSymbol: 'FAST',
    releaseTime: 1636522611,
    campaignId: '511080000',
    maxDepositAmount: 5000,
  },
  {
    id: 'undead',
    address: '0xA905e7dF99eDb1BF7aFF1b184C319Afc1971AE62',
    isActive: false,
    name: 'Undead Finance',
    subTitle: 'The Premier Gaming NFT Token on Fantom That Will Have You Scared To Death',
    description:
      'All of the scary games and bloody NFTs are accessed with $UNDEAD, the native token of UNDEAD FINANCE.',
    launchDate: 'Oct. 26',
    launchTime: '5 PM GMT',
    saleAmount: '4,000,000 UNDEAD',
    raiseAmount: '$200,000',
    cakeToBurn: '$1,500,000',
    projectSiteUrl: 'https://undead.to/',
    currency: 'USDC',
    currencyAddress: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    currencyDecimals: 6,
    tokenDecimals: 18,
    tokenSymbol: 'UNDEAD',
    releaseTime: 1635239394,
    campaignId: '511080000',
    maxDepositAmount: 3000,
  },
]

export default ifos
