import tokens from './tokens'
import { VaultConfig } from './types'

const vaults: VaultConfig[] = [
  {
    pid: 0,
    lpSymbol: 'fWINGS',
    isSingle: true,
    lpAddresses: {
      4002: '',
      250: '0x3D8f1ACCEe8e263F837138829B6C4517473d0688',
    },
    vaultAddresses: {
      4002: '',
      250: '0x442a5e9F611D0597DCe8F5F44c95235295850843',
    },
    strategyAddresses: {
      4002: '',
      250: '0x55598623EDE61a5E6e216359f0A4A7A939E7A4C2',
    },
    token: tokens.fwings,
    quoteToken: tokens.fwings,
    provider: 'Jetswap',
  },
  {
    pid: 1,
    lpSymbol: 'fWINGS-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0x1047f356D7cB29D5939724c69E4eE61e820aFAC6',
    },
    vaultAddresses: {
      4002: '',
      250: '0xdfdb96751523e410cba1a10354a374152C9B3Fc8',
    },
    strategyAddresses: {
      4002: '',
      250: '0x760ab54119a06504e5Ac12F5fD80Bd8318B06Db2',
    },
    token: tokens.fwings,
    quoteToken: tokens.wftm,
    provider: 'Jetswap',
  },
  {
    pid: 2,
    lpSymbol: 'fWINGS-USDC LP',
    lpAddresses: {
      4002: '',
      250: '0x89fF795017AE21A8696d371F685Cd02FC219F56F',
    },
    vaultAddresses: {
      4002: '',
      250: '0xFD919978845247e147364F0A1f1565AAC4Fcd472',
    },
    strategyAddresses: {
      4002: '',
      250: '0xf6FE40d3F9B14Fd69708bA1353fa1a328003DE8f',
    },
    token: tokens.fwings,
    quoteToken: tokens.usdc,
    provider: 'Jetswap',
  },
  {
    pid: 3,
    lpSymbol: 'USDC-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0x43CB347acf464a36F3D2D01c7Bf72fC6Be4b4E78',
    },
    vaultAddresses: {
      4002: '',
      250: '0x107868C516F0fb1D970e064B9dbA071973B2bF07',
    },
    strategyAddresses: {
      4002: '',
      250: '0x3b007804Bf12830053E0663EbE2710fb8f03cAb8',
    },
    token: tokens.wftm,
    quoteToken: tokens.usdc,
    provider: 'Jetswap',
  },
  {
    pid: 4,
    lpSymbol: 'fUSDT-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0x515200eF45538C952240687428E171e70A4086c6',
    },
    vaultAddresses: {
      4002: '',
      250: '0x4e25F5AdC8647dfFb2c2B5F5272666D9349eB0a2',
    },
    strategyAddresses: {
      4002: '',
      250: '0xD895b9E142390025D1CBa87aACBF1c51056254c1',
    },
    token: tokens.wftm,
    quoteToken: tokens.fusdt,
    provider: 'Jetswap',
  },
]

export default vaults
