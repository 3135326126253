import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  {
    pid: 1,
    lpSymbol: 'fWINGS-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0x1047f356D7cB29D5939724c69E4eE61e820aFAC6',
    },
    token: tokens.fwings,
    quoteToken: tokens.wftm,
  },
  {
    pid: 2,
    lpSymbol: 'fWINGS-USDC LP',
    lpAddresses: {
      4002: '',
      250: '0x89fF795017AE21A8696d371F685Cd02FC219F56F',
    },
    token: tokens.fwings,
    quoteToken: tokens.usdc,
  },
  {
    pid: 3,
    lpSymbol: 'USDC-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0x43CB347acf464a36F3D2D01c7Bf72fC6Be4b4E78',
    },
    token: tokens.wftm,
    quoteToken: tokens.usdc,
  },
  {
    pid: 4,
    lpSymbol: 'fUSDT-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0x515200eF45538C952240687428E171e70A4086c6',
    },
    token: tokens.wftm,
    quoteToken: tokens.fusdt,
  },
  {
    pid: 5,
    lpSymbol: 'ETH-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0x22133986DF9642CB47ff638a14975359aB3770C1',
    },
    token: tokens.eth,
    quoteToken: tokens.wftm,
  },
  {
    pid: 6,
    lpSymbol: 'BTC-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0xce92eBD8B17B4B713013ae88A88B61fb3f861B4e',
    },
    token: tokens.btc,
    quoteToken: tokens.wftm,
  },
  {
    pid: 7,
    lpSymbol: 'ETH-BTC LP',
    lpAddresses: {
      4002: '',
      250: '0x04C25d06eD3984AAE14CA705d3CaF97Fb7238799',
    },
    token: tokens.btc,
    quoteToken: tokens.eth,
  },
  {
    pid: 8,
    lpSymbol: 'ETH-USDC LP',
    lpAddresses: {
      4002: '',
      250: '0xD21A2c9032D09B7174dB93736b834D2a7eaDab15',
    },
    token: tokens.eth,
    quoteToken: tokens.usdc,
  },
  {
    pid: 9,
    lpSymbol: 'ETH-fUSDT LP',
    lpAddresses: {
      4002: '',
      250: '0x4281C56D06990A4239858c51eeC3C2EF87F7c1A0',
    },
    token: tokens.eth,
    quoteToken: tokens.fusdt,
  },
  {
    pid: 10,
    lpSymbol: 'BTC-USDC LP',
    lpAddresses: {
      4002: '',
      250: '0x6B5340dFcd7D509Ea931cC4E69462797DbBc0197',
    },
    token: tokens.btc,
    quoteToken: tokens.usdc,
  },
  {
    pid: 11,
    lpSymbol: 'BTC-fUSDT LP',
    lpAddresses: {
      4002: '',
      250: '0x8D470970Da37b0B324b7c5400146e26e6EAF799E',
    },
    token: tokens.btc,
    quoteToken: tokens.fusdt,
  },
  {
    pid: 12,
    lpSymbol: 'fUSDT-USDC LP',
    lpAddresses: {
      4002: '',
      250: '0xB8D08e75440b89c2c25a400Ab1883B689C0A1A64',
    },
    token: tokens.fusdt,
    quoteToken: tokens.usdc,
  },
  {
    pid: 13,
    lpSymbol: 'FTM-DAI LP',
    lpAddresses: {
      4002: '',
      250: '0x26A55D827976F9d38eCF387e99FcA9b9C0590566',
    },
    token: tokens.wftm,
    quoteToken: tokens.dai,
  },
  {
    pid: 14,
    lpSymbol: 'FTM-LINK LP',
    lpAddresses: {
      4002: '',
      250: '0xc64270817Eb8984Acaf93029b3D0DABB48476eA2',
    },
    token: tokens.link,
    quoteToken: tokens.wftm,
  },
  {
    pid: 15,
    lpSymbol: 'FTM-BOO LP',
    lpAddresses: {
      4002: '',
      250: '0x0814ed27ac0acAa77eb4Af5dFd104A0eB016F33A',
    },
    token: tokens.boo,
    quoteToken: tokens.wftm,
  },
  {
    pid: 16,
    lpSymbol: 'BHC-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0x20951B5cEC16815FE160e7a1453a94912AfD31B2',
    },
    token: tokens.bhc,
    quoteToken: tokens.wftm,
  },
  {
    pid: 17,
    lpSymbol: 'SING-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0x7443106f305085BA7e54988DCAB454097444Db5b',
    },
    token: tokens.sing,
    quoteToken: tokens.wftm,
  },
  {
    pid: 18,
    lpSymbol: 'UNDEAD-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0xd8A053C2586009D34eb31EE91314b84A4e6a83a7',
    },
    token: tokens.undead,
    quoteToken: tokens.wftm,
  },
  {
    pid: 19,
    lpSymbol: 'fPUP-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0xF0cE83239ac341941eDe0Fe9Acef8ae22d271709',
    },
    token: tokens.fpup,
    quoteToken: tokens.wftm,
  },
  {
    pid: 20,
    lpSymbol: 'HPS-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0xcf367a3494eA197089D734BAF4100a75375220E1',
    },
    token: tokens.hps,
    quoteToken: tokens.wftm,
  },
  {
    pid: 21,
    lpSymbol: 'FAST-FTM LP',
    lpAddresses: {
      4002: '',
      250: '0xE836997C9f3665986580fe98f79999fA876fC271',
    },
    token: tokens.fast,
    quoteToken: tokens.wftm,
  },
  {
    pid: 22,
    lpSymbol: 'FAST-USDC LP',
    lpAddresses: {
      4002: '',
      250: '0x4f6FefD986a40835c95E5D37bcFFFf7A85a2b4d9',
    },
    token: tokens.fast,
    quoteToken: tokens.usdc,
  },
]

export default farms